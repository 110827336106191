export const API_BASE: any = {
	DOWNLOAD: 'https://download.qft.zyddjk.net.cn/',
	SIGN_KEY: 'l]Ybbrj_ZnFz4~9VB7)Gq7[0HGCY]oq{1*I]Eji_e[(~qVRYB#axu+r6IDzeE0*EvX2&BCWt~QmXZc==',
	dev: {
		wap: 'https://wap.dev.qft.uduoduo.vip',
		api: 'https://api.dev.qft.uduoduo.vip/Public/boosoov2/',
	},
	prod: {
		wap: 'https://wap.qft.zyddjk.net.cn',
		api: 'https://api.qft.zyddjk.net.cn/Public/boosoov2/',
	},
};
